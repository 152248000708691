@font-face {
  font-family: "SFPro";
  src: url("/static/fonts/SF-Pro-Text-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SFPro";
  src: url("/static/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SFPro";
  src: url("/static/fonts/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Ttrounds";
  src: url("/static/fonts/ttroundscondensed-bold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}
